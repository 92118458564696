import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

// Import Bootstrap Theme Styles
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'

// v-smooth-scroll
import VueSmoothScroll from 'v-smooth-scroll'

// Import App Custom Styles
import './assets/sass/common.scss'
import './assets/sass/style.scss'


// createApp(App).use(router).mount('#app')
const app = createApp(App)
  .use(router)
  .use(VueSmoothScroll, {
    duration: 100,
    updateHistory: false,
  });

router.isReady().then(() => {
  app.mount('#app');
});
